<template>
    <template v-if="title">
        <i :class="class" aria-hidden="true"></i>
        <span class="sr-only">{{ title }}</span>
    </template>

    <template v-else>
        <span :class="class" aria-hidden="true"></span>
    </template>
</template>

<script>
export default {
  props: {
    title: String,
    class: String,
  }
}
</script>